import React from "react";
import One from "../../components/agency_templates/One";
import {
  websiteExample,
  pageLinks,
} from "../../components/agency_templates/website_example";
// Variables

const one = ({ website }) => {
  return <One website={website ?? websiteExample} pageLinks={pageLinks} />;
};

export default one;
