import React from "react";
import {
  Stack,
  Box,
  Container,
  Link,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import { grey } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";

const backgroundGrey = grey[50];
const textGreyLight = grey[500];
const textGrey = grey[700]; //"rgba(70,70,70,1)";
const textGreyDarker = grey[800]; //"rgba(49,49,49,1)";
const borderColors = [
  "rgb(0,0,136)", // Dark BLue
  "rgb(165,0,174)", // Purple
  "rgb(227,85,16)", // Orange
  "rgb(115,217,11)", // Light Green
];

const ContainerRender = ({ secondaryColor, intro, nav, sx, ...props }) => {
  const padding = 6;
  const paddingD = nav
    ? { py: 2 }
    : { pt: intro ? 2 : padding, pb: intro ? 10 : padding };
  const Cont = (
    <Container maxWidth="md" sx={{ ...paddingD, ...sx }} {...props} />
  );
  return secondaryColor ? (
    <Box backgroundColor={backgroundGrey}>{Cont}</Box>
  ) : (
    Cont
  );
};
const DotDisplay = ({ row, responsive, ...props }) => (
  <Stack
    direction={
      row ? "row" : { xs: responsive ? "row" : "column", md: "column" }
    }
    fontSize={12}
    spacing={2}
    {...props}
  >
    <CircleIcon sx={{ color: borderColors[1] }} fontSize="inherit" />
    <CircleIcon sx={{ color: borderColors[2] }} fontSize="inherit" />
    <CircleIcon sx={{ color: borderColors[3] }} fontSize="inherit" />
  </Stack>
);
const Heading = ({ sx, intro, component, small, ...props }) => (
  <Typography
    component={component ?? (intro ? "h1" : small ? "h6" : "h2")}
    variant={intro ? "h1" : small ? "h4" : "h3"}
    gutterBottom
    sx={{
      textTransform: "uppercase",
      fontWeight: 500,
      color: !small ? textGreyDarker : textGrey,
      ...sx,
    }}
    {...props}
  />
);
const TitleContainer = ({ title, subtitle, reverse, children }) => {
  const breakPoint = "md";
  const bp = { xs: 12, [breakPoint]: 6 };
  const gridProps = reverse
    ? {
        one: {},
        two: {
          justifyContent: { xs: "center", [breakPoint]: "end" },
          container: true,
          sx: { pl: 3 },
          direction: { xs: "column", [breakPoint]: "row" },
          alignItems: "center",
          alignContent: "center",
          spacing: { xs: 3, [breakPoint]: 0 },
        },
      } // Subtitle - Title
    : {
        one: {
          container: true,
          sx: { pr: 3 },
          direction: { xs: "column", [breakPoint]: "row" },
        },
        two: { justifyContent: { xs: "center", [breakPoint]: "left" } },
      }; // Title - Subtitle
  const sub = <Typography variant="body1">{subtitle}</Typography>;
  const t = (
    <>
      {!reverse && <DotDisplay mr={2} responsive />}
      {title ? <Heading gutterBottom={false}>{title}</Heading> : children}
      {reverse && <DotDisplay ml={2} responsive />}
    </>
  );
  return (
    <Grid
      container
      sx={{ mb: 3 }}
      direction={
        reverse ? { xs: "column-reverse", [breakPoint]: "row" } : "row"
      }
    >
      <Grid
        item
        {...bp}
        container
        justifyContent={{ xs: "center", [breakPoint]: "left" }}
        mb={{ xs: 3, [breakPoint]: 0 }}
        {...gridProps.one}
      >
        {reverse ? sub : t}
      </Grid>
      <Grid item {...bp} {...gridProps.two}>
        {reverse ? t : sub}
      </Grid>
    </Grid>
  );
};
const RenderService = ({
  data: {
    name: text,
    imageUrl,
    subtext,
    headingProps,
    subtextProps,
    subtextSx,
  },
}) => {
  return (
    <Box>
      {text && (
        <Heading small gutterBottom={false} {...headingProps}>
          {text}
        </Heading>
      )}
      {subtext && (
        <Typography
          variant="h6"
          sx={{ color: textGreyLight, fontWeight: 400, ...subtextSx }}
          {...subtextProps}
        >
          {subtext}
        </Typography>
      )}
    </Box>
  );
};
const LinkRender = ({ to, text, children }) => (
  <Link
    href={to}
    underline="none"
    sx={{
      color: "rgb(130,130,130)",
      transition: "all .2s",
      "&:hover": { color: textGreyDarker, fontWeight: 500 },
    }}
  >
    {text ?? children}
  </Link>
);
const One = ({ website, pageLinks: pageLinksDefault }) => {
  // For this template services have to be separated into a grid of rows
  const services = website.services;
  const portfolios = website.portfolios;
  const pageLinks = website.pageLinks ?? pageLinksDefault;
  const generateServiceRows = () =>
    services.map((service, index) => [
      index % 2 == 0 ? service : {},
      index % 2 == 0 ? {} : service,
    ]);

  const generatePortfolioRows = () =>
    portfolios.map((portfolio) => [
      {
        subtext: portfolio.yearCompleted,
        subtextSx: { transform: "rotate(-90deg)" },
        subtextProps: { variant: "h5" },
      },
      { name: portfolio.title, subtext: portfolio.portfolioType?.name },
      {},
    ]);
  const [serviceRows, setServiceRows] = React.useState(generateServiceRows());
  const [portfolioRows, setPortfolioRows] = React.useState(
    generatePortfolioRows()
  );
  return (
    <>
      <ContainerRender nav component="nav" intro secondaryColor>
        <Stack direction="row" spacing={3} width="100%">
          <Box color={textGreyDarker} fontWeight="bold">
            {website.brandDisplayName ?? "AGENCY"}
          </Box>
          <Box display="flex" sx={{ flex: 1, justifyContent: "center" }}>
            {pageLinks.map(LinkRender)}
          </Box>
          <LinkRender to="#">Contact Us</LinkRender>
        </Stack>
      </ContainerRender>
      <ContainerRender intro secondaryColor>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Heading intro>Digital Magical</Heading>
            <Box pl={10}>
              <Typography variant="body1" sx={{ mb: 3 }}>
                {website.pageSlogan}
              </Typography>
              <Button
                variant="text"
                size="large"
                sx={{ ml: -1, pr: 3 }}
                endIcon={<ArrowForwardRoundedIcon fontSize="inherit" />}
              >
                <Typography
                  component="span"
                  variant="h5"
                  sx={{
                    color: textGreyDarker,
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                >
                  Let's Talk
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ContainerRender>
      <ContainerRender>
        <TitleContainer
          title="SERVICES"
          subtitle={website.servicesDescription}
          reverse
        />
        <Grid container sx={{ pt: 3 }}>
          <Grid
            item
            container
            md={8}
            xs={12}
            // alignItems="stretch"
          >
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="1fr"
              rowGap={1}
            >
              {serviceRows.map(([column1, column2], i) => (
                <>
                  <Box
                    gridColumn="span 7"
                    sx={{
                      pl: 3,
                      py: 3,
                      borderLeft: "3px solid",
                      borderColor: borderColors[i],
                      // height: 90,

                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <RenderService data={column1} />
                  </Box>
                  <Box
                    gridColumn="span 5"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RenderService data={column2} />
                  </Box>
                </>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            container
            direction="column"
            justifyContent="center"
            sx={{ px: 3 }}
          >
            <DotDisplay row mb={2} />
            <Heading
              small
              gutterBottom={false}
              sx={{ color: textGreyDarker }}
              variant="h6"
            >
              ART DIRECTION & bRAND IDENTITY
            </Heading>
          </Grid>
        </Grid>
      </ContainerRender>
      <ContainerRender>
        <TitleContainer
          title="About US"
          subtitle={website.aboutUsVideoLink && website.aboutUs}
        />
        {website.aboutUsVideoLink ? (
          <Box
            sx={{
              height: 400,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              fontSize: 110,
              border: 2,
              mt: 3,
            }}
          >
            {website.aboutUsVideoLink === true ? (
              <PlayCircleFilledRoundedIcon fontSize="inherit" />
            ) : (
              <video playsInline controls>
                <source src={website.aboutUsVideoLink} />
                Your browser doesn't support this video{" "}
              </video>
            )}
          </Box>
        ) : (
          <Box>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              {website.aboutUs}
            </Typography>
          </Box>
        )}
      </ContainerRender>
      {(website.portfolioDescription || website.portfolios.length > 0) && (
        <ContainerRender>
          <TitleContainer
            title="Project"
            subtitle={website.portfolioDescription}
          />
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="1fr"
            rowGap={1}
            pt={3}
          >
            {portfolioRows.map(([column1, column2, column3], i) => (
              <>
                <Box
                  gridColumn="span 1"
                  sx={{
                    px: 2,
                    py: 3,
                    borderLeft: "3px solid",
                    borderColor: borderColors[4 % (i + 1)],
                    // height: 90,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <RenderService data={column1} />
                </Box>
                <Box gridColumn="span 7">
                  <RenderService data={column2} />
                </Box>
                <Box gridColumn="span 4">
                  <RenderService data={column3} />
                </Box>
              </>
            ))}
          </Box>
        </ContainerRender>
      )}
      <ContainerRender secondaryColor sx={{ position: "relative" }}>
        <DotDisplay row position="absolute" top={-5} />
        <Heading
          component="a"
          sx={{ textDecoration: "none" }}
          href={`mailto:${website.contactEmail}`}
        >
          {website.contactEmail}
        </Heading>
      </ContainerRender>
    </>
  );
};

export default One;
